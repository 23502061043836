import { useParams, useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import { MenuOptions } from "../Header/Menu/types";

type LibraryParams = {
  menuOption: MenuOptions;
};

export const useGetMenuOption = () => {
  const { menuOption: selectedOption } = useParams<LibraryParams>();
  const possibleMenuOptions = Object.values(MenuOptions);
  const isValidMenuOption = possibleMenuOptions.includes(selectedOption);

  return { selectedOption, isValidMenuOption };
};

export function useClearSearchParamsForFamilyMode() {
  const history = useHistory();
  const location = useLocation();

  const { isFamilyModeOn, loadingIsFamilyModeOn } = useFamilyModeSetting();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loadingIsFamilyModeOn) {
      return;
    }

    setLoading(false);

    if (!(isFamilyModeOn && location.search)) {
      return;
    }

    history.replace({ search: "" });
  }, [isFamilyModeOn, loadingIsFamilyModeOn]);

  return { clearingSearchForFamilyMode: loading };
}

/**
 * useForceRerenderOnModeSwitch
 *
 * Use to quickly "reload" (re-render) downstream components
 * by using exposed state to toggle <Loader/> briefly.
 *
 * Downstream search components capture search params immediately on mount,
 * meaning that subsequent updates to search (query string) won't matter,
 * & causes switching into family mode to break.
 *
 * Also opting to force re-render on Family exit b/c for whatever reason,
 * cannot scroll once in Studio mode (likely to do with captured state).
 * Side note,this will run once unnecessarily, but not a big deal.
 *
 */
export function useForceRerenderOnModeSwitch() {
  const { isFamilyModeOn, loadingIsFamilyModeOn } = useFamilyModeSetting();
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      if (loading || loadingIsFamilyModeOn) {
        return;
      }

      setLoading(true);
    },
    // !Important to not have `loading` as a dep to avoid endless loop.
    [isFamilyModeOn, loadingIsFamilyModeOn]
  );

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [loading]);

  return { rerenderingOnModeSwitch: loading };
}
