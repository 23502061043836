import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Resets pagination to 1 (page=1) on mount to not miss full list of classes.
export function useResetInitialPagination() {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);

    if (!location.search) {
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");

    if (!page || page === "1") {
      return;
    }

    searchParams.set("page", "1");
    history.replace({ search: searchParams.toString() });
  }, []);

  return { resettingPagination: loading };
}
